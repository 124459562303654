<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="子标题" prop="sub_title">
        <el-input v-model="info.sub_title" placeholder="请输入子标题"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="imgChoose"></SelectImgs>
        <span>340*340px</span>
      </el-form-item>
      <el-form-item label="金额" prop="amount">
        <el-input v-model="info.amount" placeholder="请输入金额"></el-input>
      </el-form-item>
      <el-form-item label="会员天数" prop="days">
        <el-input v-model="info.days" placeholder="请输入天数"></el-input>
      </el-form-item>
      <el-form-item label="免运费次数" prop="postage_free">
        <el-input v-model="info.postage_free" placeholder="请输入子次数"></el-input>
      </el-form-item>
      <el-form-item label="免租金次数" prop="rent_num">
        <el-input v-model="info.rent_num" placeholder="请输入次数"></el-input>
      </el-form-item>
      <el-form-item label="权益说明" prop="intro">
        <wang-edit :txt="info.intro" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="排序倒序" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import WangEdit from "@/components/WangEdit";
export default {
  data() {
    return {
      info: {
        id: 0,
        title: '',
        img: '',
        sub_title: '',
        amount: '',
        days: '',
        postage_free: '',
        rent_num: '',
        intro: '',
      },
      img: [],
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
      }
    };
  },
  created() {
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
    WangEdit,
    SelectImgs
  },
  computed: {
  },
  methods: {
    setText(txt) {
      this.info.intro = txt
    },
    imgChoose(img) {
      this.info.img = img.pic
    },
    getInfo() {
      var that = this
      this.$api.member.memberGradeInfo({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.member.memberGradeEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 800px;
  }
</style>
